import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { EmployeeMaster } from "src/app/Models/employeemaster";
import { ApiService } from "src/app/Service/api.service";
import { AddemployeemasterComponent } from "../addemployeemaster/addemployeemaster.component";

@Component({
  selector: "app-employeemasterlist",
  templateUrl: "./employeemasterlist.component.html",
  styleUrls: ["./employeemasterlist.component.css"],
})
export class EmployeemasterlistComponent implements OnInit {
  formTitle = "Manage Employees";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  @Input()
  dataList2: any[] = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  orgId = this.cookie.get("orgId");

  columns: string[][] = [
    ["NAME", "Employee Name"],
    ["EMAIL_ID", "Email"],
    ["MOBILE_NUMBER", "Mobile Number"],
    ["DESIGNATION_NAME", "Designation"],
    ["BRANCH_NAME", "Branch"],
    ["DEPARTMENT_NAME", "Department Name"],
    ["EMPLOYEE_REPORTING_HEAD_NAME", "Reporting Head"],
    ["EMPLOYEE_TEMPRARY_HEAD_NAME", "Temporary Head"],
    ["EMPLOYEE_CODE", "Employee Code"],
    ["CLOUD_ID", "Cloud ID"],
  ];

  columns1: string[][] = [
    ["NAME", "Employee Name"],
    ["EMAIL_ID", "Email"],
    ["MOBILE_NUMBER", "Mobile Number"],
    ["DESIGNATION_NAME", "Designation"],
    ["BRANCH_NAME", "Branch"],
    ["DEPARTMENT_NAME", "Department Name"],
    ["EMPLOYEE_REPORTING_HEAD_NAME", "Reporting Head"],
    ["EMPLOYEE_TEMPRARY_HEAD_NAME", "Temporary Head"],
    ["EMPLOYEE_CODE", "Employee Code"],
    ["SEQUENCE_NO", "Sequence no"],
  ];

  drawerVisible: boolean;
  drawerVisible2: boolean;
  drawerTitle: string;
  drawerData: EmployeeMaster = new EmployeeMaster();
  applicationId = Number(this.cookie.get("applicationId"));
  drawerVisible1: boolean;
  drawerTitle1: string;
  listOfData: EmployeeMaster[] = [];
  OPEN_TIME2 = null;
  CLOSE_TIME2 = null;
  DAYS = false;
  table2 = 0;
  dataList3 = [];
  reportinghead = [];

  constructor(private api: ApiService, private cookie: CookieService) {}

  ngOnInit() {
    this.search();
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
  }

  search(reset: boolean = false) {
    var filter = "";
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    console.log("search text:" + this.searchText);
    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns1.forEach((column) => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    if (likeQuery) filter += likeQuery;

    this.api
      .getAllemployeeMaster(
        this.pageIndex,
        this.pageSize,
        this.sortKey,
        sort,
        likeQuery +
          " AND  ORG_ID =" +
          this.orgId +
          " AND ID !=" +
          Number(this.cookie.get("userId"))
      )
      .subscribe(
        (data) => {
          console.log(data);
          this.loadingRecords = false;
          this.totalRecords = data["count"];
          this.dataList = data["data"];

          if (this.totalRecords == 0) {
            data.SEQUENCE_NO = 1;
          } else {
            data.SEQUENCE_NO =
              this.dataList[this.dataList.length - 1]["SEQUENCE_NO"] + 1;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }

  add(): void {
    this.drawerData = new EmployeeMaster();
    this.drawerTitle = "Create New Employee";

    this.api
      .getAllemployeeMaster(
        0,
        0,
        "",
        "",
        " AND  ID in(SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID = " +
          this.orgId +
          " AND ROLE_ID<>2)   AND STATUS=1  "
      )
      .subscribe(
        (data) => {
          this.reportinghead = data["data"];
        },
        (err) => {}
      );

    this.api
      .getAllemployeeMaster(
        1,
        1,
        "SEQUENCE_NO",
        "desc",
        "" + " AND  ORG_ID =" + this.orgId
      )
      .subscribe(
        (data) => {
          if (data["count"] == 0) {
            this.drawerData.SEQUENCE_NO = 1;
          } else {
            this.drawerData.SEQUENCE_NO = data["data"][0]["SEQUENCE_NO"] + 1;
          }
        },
        (err) => {
          console.log(err);
        }
      );

    this.drawerVisible = true;
  }

  @ViewChild(AddemployeemasterComponent, { static: false })
  AddemployeemasterComponentVar: AddemployeemasterComponent;

  edit(data: EmployeeMaster): void {
    this.drawerTitle = "Update Employee Information";
    this.drawerData = Object.assign({}, data);

    if (this.drawerData.DEPARTMENT_ID == 0) this.drawerData.DEPARTMENT_ID = 0;

    if (this.drawerData.BRANCH_ID == 0) this.drawerData.BRANCH_ID = 0;

    if (this.drawerData.DESIGNATION_ID == 0) this.drawerData.DESIGNATION_ID = 0;

    if (this.drawerData.REPORTING_HEAD_ID == 0)
      this.drawerData.REPORTING_HEAD_ID = 0;

    if (this.drawerData.TEMPORARY_HEAD_ID == 0)
      this.drawerData.TEMPORARY_HEAD_ID = 0;

    this.DAYS = false;
    var d = "";

    if (data.ID != undefined && data.ID > 0) {
      d = " AND ID !=" + data.ID;
    }

    this.api
      .getAllemployeeMaster(
        0,
        0,
        "",
        "",
        " AND STATUS=1 AND ORG_ID=" + this.orgId + d
      )
      .subscribe(
        (data) => {
          this.reportinghead = data["data"];
        },
        (err) => {}
      );

    this.AddemployeemasterComponentVar.getallorg2(data.ID);
    this.drawerVisible = true;
  }
  role1: any;
  DEPARTMENT_NAME:any=[]
  ID:any
  map(data) {
    this.drawerTitle = "Map Roles";
    this.drawerData = Object.assign({}, data);
    console.log(this.drawerData.ID, "ID");
    this.api.gettickdeskDepartmentAdminMapping(0,0,"",""," AND EMPLOYEE_ID=" + data.ID).subscribe((data) => {
        this.dataList36 = data["data"];
        for(var i=0;i<this.dataList36.length;i++){
          this.DEPARTMENT_NAME.push(this.dataList36[i]['DEPARTMENT_NAME'])
        }
        console.log(this.DEPARTMENT_NAME)
      });
    this.api.getAllEmpRoleMap(0, 0, "ID", "ASC", " AND EMPLOYEE_ID=" + data.ID).subscribe((data) => {
        this.dataList3 = data['data'];
     
        this.drawerVisible2 = true;
  
     
        console.log(this.role1,'1')
      });
  }
  dataList36: any;
  drawerClose(): void {
    this.search();
   
    this.drawerVisible = false;
  }

  drawerClose1(): void {
    this.search();
    this.drawerVisible1 = false;
    console.log(this.drawerVisible);
  }

  drawerClose2(): void {
    this.search();
    this.drawerVisible2 = false;
  
  }

}
