import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';
import { ExportService } from 'src/app/Service/export.service';
import { ApiService } from 'src/app/Service/api.service';
@Component({
  selector: 'app-project-wise-report',
  templateUrl: './project-wise-report.component.html',
  styleUrls: ['./project-wise-report.component.css']
})
export class ProjectWiseReportComponent implements OnInit {

  formTitle = "Project Wise Report";
  employeenm = ''
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  fileName = 'Project Wise Report.xlsx';
  dataList = [];
  dataListForExport = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: any = "default";
  columns: string[][] =[ ['TICKET_GROUP_VALUE', "Project Name"],["TOTAL_TICKETS", "Total Tickets"],["PENDING", "Pending"],["ASSIGNED", "Assigned"],["ANSWERED", "Answered"],["RE_OPEN", "Reopen"],["CLOSED", "Closed"],["ON_HOLD","On Hold"],
  ["BANNED","Banned"],["CLOSED_BEFORE_24", "Closed in 24 hrs"],["CLOSED_BETWEEN_24_48", "Closed in 48 hrs"],["CLOSED_BETWEEN_48_72", "Closed in 72 hrs"],["CLOSED_AFTER_72", "Closed in > 72 hrs"]]
  columns1: string[][] =[ ['TICKET_GROUP_VALUE', "Project Name"] ]
  BRANCH = [];
  SUPPORT_USER = 'AL';
  isSpinning = false;
  filterClass: string = "filter-invisible";
  selectedDate: Date[] = [];
  dateFormat = 'dd-MM-yyyy';
  date: Date[] = [];
  data1 = [];
  index = 0;
  ticketQuestion = {};
  value1: string = "";
  value2: string = "";
  leaves = [];
  supportusers = [];
  orgId = Number(this.cookie.get('orgId'));
  date1 = new Date();
  date2 = new Date();
  today = new Date();
  isButtonSpinning: boolean = false;
  VIN_DATE: any = new Date();
  TO_DATE: any = new Date();
  endOpen = false;
  // startOpen = false;
  employees = [];
  LEAVE_TYPE: any;
  APPROVAR_ID: any;
  month = new Date().getMonth().toString() + (new Date().getMonth() + 1).toString()
  year = new Date().getFullYear()
  MONTH: any = this.month;
  MONTH1: any = new Date()
  YEAR: any = this.year
  monthFormat = "MMM";

  constructor(private api: ApiService, private datePipe: DatePipe,
    private cookie: CookieService, private _exportService: ExportService, private message: NzNotificationService) { }

  ngOnInit(): void {
    this.search();
    // this.applyFilter();
  }

models = [];





  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search();
  }

  keyup(event: any) {
    this.search();
  }

  dates: any = [];
  today2 = new Date();

 

  getDaysArray(start: any, end: any) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
      this.dates.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
    }
    return arr;
  }

  timeDefaultValue = setHours(new Date(), 0);

 

  showFilter() {
    
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }

  
  onStartChange(date: Date): void {
    this.VIN_DATE = date;
  }
  onEndChange(date: Date): void {
    this.TO_DATE = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
  }
  
  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }





  setDateForsalesWiseFilter() {
    this.date = [];
    let currentDate = new Date();
    let previous15thDayDate = currentDate.setDate(currentDate.getDate() + (-30));
    this.date1 = new Date(previous15thDayDate);
    this.date2 = new Date();
  }



  supportAgentWiseDeptArray = [];

  exportLoading: boolean = false;
  importInExcel() {
    this.search(true, true);
  }
  
  dataList2=[]
  search(reset: boolean = false, exportInExcel: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }

    //  this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    console.log("search text : " + this.searchText);
    // console.log(sort);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND ";

      this.columns1.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }

   
   if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.ProjectWiseReport(this.pageIndex, this.pageSize, this.sortKey, sort ,this.filterQuery+likeQuery,this.orgId).subscribe(data => {
        console.log(data['data']);
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
     }, err => {
        console.log(err);
      });
  }
    else {
      this.exportLoading = true;
      this.api.ProjectWiseReport(0,0, this.sortKey,sort,this.filterQuery+likeQuery,this.orgId).subscribe(data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.dataList2 = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
       });
    }
}



sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
}
               
convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList2.length; i++) {
      obj1['Project Name'] = this.dataList2[i]['TICKET_GROUP_VALUE'];
      obj1['Total Tickets Assigned'] = this.dataList2[i]['TOTAL_TICKETS'];
      obj1['Pending'] = this.dataList2[i]['PENDING'];
      obj1['Assigned'] = this.dataList2[i]['ASSIGNED'];
      obj1['Answered'] = this.dataList2[i]['ANSWERED'];
      obj1['Reopen'] = this.dataList2[i]['RE_OPEN'];
      obj1['Closed'] = this.dataList2[i]['CLOSED'];
      obj1['On Hold'] = this.dataList2[i]['ON_HOLD'];
      obj1['Banned'] = this.dataList2[i]['BANNED'];
      obj1['Closed in 24 hrs'] = this.dataList2[i]['CLOSED_BEFORE_24'];
      obj1['Closed in 48 hrs'] = this.dataList2[i]['CLOSED_BETWEEN_24_48'];
      obj1['Closed in 72 hrs'] = this.dataList2[i]['CLOSED_BETWEEN_48_72'];
      obj1['Closed in > 72 hrs'] = this.dataList2[i]['CLOSED_AFTER_72'];

      
      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList2.length - 1) {
        this._exportService.exportExcel(arry1, 'Project Wise Report' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
   }
}

}
