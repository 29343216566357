import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Faq } from 'src/app/Models/faq';

import { ApiService } from 'src/app/Service/api.service';
import { FaqresponsesComponent } from 'src/app/pages/SupportModule/FAQ/faqresponses/faqresponses.component';
import { CookieService } from 'ngx-cookie-service';
import { FaqComponent } from '../faq/faq.component';


@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css']
})
export class FaqsComponent implements OnInit {
  formTitle = "Manage FAQs";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["FAQ_HEAD_NAME", "FAQ Head Name"], ["QUESTION", "Question"], ["ANSWER", "Answer"], ["TAGS", "Tags"], ["POSITIVE_COUNT", "Positive Count"], ["NEGATIVE_COUNT", "Negative Count"], ["SEQ_NO", "Sequence No"]]
  applicationId = Number(this.cookie.get('applicationId'))
  @ViewChild(FaqresponsesComponent, { static: false }) faqResponse1: FaqresponsesComponent;
  // @ViewChild(FaqComponent,{static:false}) myInputVariable: ElementRef;
  // @ViewChild('inputFile') myInputVariable: ElementRef;

  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Faq = new Faq();

  drawerVisible1: boolean;
  drawerTitle1: string;
  drawerData1: Faq = new Faq();
  constructor(private api: ApiService, private cookie: CookieService) { }
  ngOnInit() {
    this.search();
  }
  // Basic Methods
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    var likeQuery = " ";
    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) +")"

    }

    if (likeQuery)
      this.filterQuery = "AND APPLICATION_ID=" + this.applicationId + likeQuery
    else
      this.filterQuery = "AND APPLICATION_ID=" + this.applicationId

    console.log(this.filterQuery)

    this.api.getAllFaqs(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + ' AND  ORG_ID =' + this.cookie.get('orgId')).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
      if (this.totalRecords == 0) {
        data['SEQ_NO'] = 1;
      } else {
        data['SEQ_NO'] = this.dataList[this.dataList.length - 1]['SEQ_NO'] + 1
      }
    }, err => {
      console.log(err);
    });
  }
  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }
  add(): void {
    this.drawerTitle = "Create New FAQ";
    this.drawerData = new Faq();
    this.drawerData.STATUS = true;
    this.drawerData.FAQ_HEAD_ID = null;
    this.drawerData.QUESTION = '';
    this.drawerData.ANSWER = '';
    this.drawerData.TAGS = '';
    this.drawerData.URL = '';
    this.drawerData.TAGS_STRING = [];
    // this.form.nativeElement.reset()
    // this.myInputVariable.nativeElement.value = '';


    // this.drawerVisible = true;
    this.api.getAllFaqs(1, 1, 'SEQ_NO', 'desc',  ' AND  ORG_ID =' + this.cookie.get('orgId')).subscribe(data => {
      if (data['count'] == 0) {
        this.drawerData.SEQ_NO = 1;
      } else {
        this.drawerData.SEQ_NO = data['data'][0]['SEQ_NO'] + 1;
      }
    }, err => {
      console.log(err);
    })
    this.drawerVisible = true;
  }
  URL
  edit(data: Faq): void {
    this.drawerTitle = "Update FAQ";
    try {
      data.TAGS_STRING = data.TAGS.split(",");
    } catch (error) {
      data.TAGS_STRING = [];
    }
    this.drawerData = Object.assign({}, data);
    this.URL=data.URL
    this.drawerVisible = true;

    
    if ((this.drawerData.URL != " ") && (this.drawerData.URL != null))
    this.drawerData.URL = this.drawerData.URL;

  else
    this.drawerData.URL = null;
  
  }

  

  ViewResponses(data: Faq) {
    this.drawerTitle1 = "FAQ Responses";
    this.drawerData1 = Object.assign({}, data);
    this.drawerVisible1 = true;
    this.faqResponse1.applyFilter(data.ID);
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  drawerClose1(): void {
    this.search();
    this.drawerVisible1 = false;
  }
}
