import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';
import { ExportService } from 'src/app/Service/export.service';
import { ApiService } from 'src/app/Service/api.service';
@Component({
  selector: 'app-todays-count-report',
  templateUrl: './todays-count-report.component.html',
  styleUrls: ['./todays-count-report.component.css']
})
export class TodaysCountReportComponent implements OnInit {
  formTitle = "Todays Count";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  fileName = 'Todays Count Report.xlsx';
  dataList = [];
  dataListForExport = [];
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: any = "default";
  columns: string[][] =[['CREATOR_EMPLOYEE_NAME', "Total New Tickets Created"],["TOTAL_TICKETS", "Assigned Tickets"],["TODAY_CREATED", "Answered Tickets"],["TODAY_RE_OPEN", "Closed Tickets"]]
  columns1: string[][] =[['CREATOR_EMPLOYEE_NAME', "Employee Name"]]
  SUPPORT_USER = 'AL';
  isSpinning = false;
  filterClass: string = "filter-invisible";
  selectedDate: Date[] = [];
  dateFormat = 'dd-MM-yyyy';
  date: Date[] = [];
  index = 0;
  ticketQuestion = {};
  supportusers = [];
  orgId = Number(this.cookie.get('orgId'));
  date1 = new Date();
  date2 = new Date();
  today = new Date();
  isButtonSpinning: boolean = false;
  VIN_DATE: any = new Date();
  TO_DATE: any = new Date();
  endOpen = false;
  employees = [];

constructor(private api: ApiService, private datePipe: DatePipe,private cookie: CookieService, 
private _exportService: ExportService, private message: NzNotificationService) { }

ngOnInit(): void {
    this.search();
    // this.applyFilter();
}

models = [];
onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search();
}

keyup(event: any) {
    this.search();
}

  dates: any = [];
  today2 = new Date();

 

  getDaysArray(start: any, end: any) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
      this.dates.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
    }
    return arr;
  }

  timeDefaultValue = setHours(new Date(), 0);

  // moduleStartDateHandle(open: boolean) {
  //   if (!open) {
  //     this.endOpen = true;
  //   }
  // }


  showFilter() {
    
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }

  

  onStartChange(date: Date): void {
    this.VIN_DATE = date;
  }
  onEndChange(date: Date): void {
    this.TO_DATE = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
  }
  
  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }

  applyFilter() {

    
if ((this.VIN_DATE != undefined))
    this.isFilterApplied = "primary";
else
    this.isFilterApplied = "default";
    this.search(true);
    this.filterClass = "filter-invisible";
  

}


  setDateForsalesWiseFilter() {
    this.date = [];
    let currentDate = new Date();
    let previous15thDayDate = currentDate.setDate(currentDate.getDate() + (-30));
    this.date1 = new Date(previous15thDayDate);
    this.date2 = new Date();
  }

clearFilter() {
    this.filterClass = 'filter-invisible';
    this.VIN_DATE = new Date();
    this.isFilterApplied = 'default';
    this.filterQuery = '';
    this.dataList = [];
    this.search(false);
}

  supportAgentWiseDeptArray = [];

  exportLoading: boolean = false;
  importInExcel() {
    this.search(true, true);
  }
  
  dataList2=[]
  search(reset: boolean = false, exportInExcel: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }

    //  this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    console.log("search text : " + this.searchText);
    // console.log(sort);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND ";

      this.columns1.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }

   
   if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.GrowthWiseReport(this.pageIndex, this.pageSize, this.sortKey, sort ,this.filterQuery+likeQuery).subscribe(data => {
        console.log(data['data']);
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
     }, err => {
        console.log(err);
      });
  }
    else {
      this.exportLoading = true;
      this.api.GrowthWiseReport(0,0, this.sortKey,sort,this.filterQuery+likeQuery).subscribe(data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.dataList2 = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
       });
    }
}


sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
}
               
convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList2.length; i++) {
      obj1['Total New Tickets Created'] = this.dataList2[i]['TODAY_TICKET'];
      obj1['Assigned Tickets'] = this.dataList2[i]['TODAY_ASSIGNED_TICKET'];
      obj1['Answered Tickets'] = this.dataList2[i]['TODAY_ANSWERED_TICKET'];
      obj1['Closed Tickets'] = this.dataList2[i]['TODAY_CLOSED_TICKET'];
     
    arry1.push(Object.assign({}, obj1));
      if (i == this.dataList2.length - 1) {
        this._exportService.exportExcel(arry1, 'Todays Count' + this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      }
   }
}

}
